import {useCaseStudyImage} from './useCaseStudyImage';
import Image from 'gatsby-image';
import React from 'react';

export const useEnCaseStudyData = () => {
  const data = useCaseStudyImage();

  return [
    {
      title: (
        <>
          Cersanit and Opoczno - a shop-in-shop project.
        </>
      ),
      shortText: (
        <>
          The marketing offensive conducted by Rovese Group (the owner of Cersanit and Opoczno brands) on the domestic and foreign tiles and ceramic sanitary ware markets resulted in a number of jointly implemented projects and promotional actions
        </>
      ),
      text: (
        <>
          The marketing offensive conducted by Rovese Group (the owner of Cersanit and Opoczno brands) on the domestic and foreign tiles and ceramic sanitary ware markets resulted in a number of jointly implemented projects and promotional actions. Our actions, aimed at effective support of brand identification and sales of an assortment of 3D (baths, washbasins, bathroom furniture) and 2D (ceramic and porcelain tiles) products were broken down into two stages of implementation: displays and showrooms. The outcome of the cooperation was preparation of shops for both brands with refreshed identification and a new format.
<br/>
          We completed the project by encompassing it within a group of complementary services covering the areas of technical development, manufacture of commercial devices, logistics, and distribution, together with assembly and later, regular replacement of the exhibited assortment at the POS.
          Due to different spatial possibilities, each shop was worked out individually by our design and construction department. After measurements were taken and consultations made with the owner of the POS, as well as the relevant attendant, dedicated built-in units, decorations, and commercial devices were integrated into the design, all while considering the maintenance of proper ergonomics and aesthetics with the existing fittings.
          <br/>
          Our experienced production staff dealt with the manufacture as well as the packaging and customising of the display sets in close cooperation with our logistics department, taking special care to optimise distribution to shops in different areas of the country and abroad, as shops were being opened in Poland and Romania.
          <br/>
          Thank you for your trust. We hope we’ve earned your future business.
        </>
      ),
      href: '/en/case-study/cersanit',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_1.childImageSharp.fluid}
        />
      ),
    },
    {
      title: <>Futuro and Viscoplast – mini-campaigns.</>,
      shortText: (
        <>
          This project assumed preparation of a&nbsp;set of POS materials for the products of Futuro, Nexcare, and Viscoplast brands, all owned by 3M. Our tasks included formulating the design...
        </>
      ),
      text: (
        <>
          This project assumed preparation of a&nbsp;set of POS materials for the products of Futuro, Nexcare, and Viscoplast brands, all owned by 3M. Our tasks included formulating the design of a possibly universal set of sales & promotional displays dedicated mainly to pharmacies, sporting goods shops, and medical supply shops.
          <br/>
          In the case of Futuro, a brand of modern compression bands, we prepared solutions for smaller- and larger-format shops. Depending on the available space or needs of the owners of the POS owners, we offered floor stands, counter stands, and mountable stands. The identification of Nexcare and Viscoplast plasters and dressing brands was strengthened thanks to the implementation of the new types of displays. Similar to the case of Futuro, our proposal covered shops of different sizes and available space.
          <br/>
          The scope of all projects included preparation of visualisations based on the analysis of the needs of the client and of the merchandising possibilities of the target POS. From the start of the technological study & construction, we took into account the need to distribute our displays all over Europe, as well as outside its borders.
        </>
      ),
      href: '/en/case-study/futuro-viscoplast',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_2.childImageSharp.fluid}
        />
      ),
    },
    {
      title: (
        <>
          Shell deli2go – Snack bars at petrol stations.
        </>
      ),
      shortText: (
        <>
          The main assumption of the deli2go project was to create a&nbsp;cosy area of shop-in-shop snack bars at Shell petrol stations. It was assumed that the aesthetics and functionality would allow the driver...
        </>
      ),
      text: (
        <>
          The main assumption of the deli2go project was to create a&nbsp;cosy area of shop-in-shop snack bars at Shell petrol stations. It was assumed that the aesthetics and functionality would allow the driver to relax for a while with a cup of fresh coffee as well as a wide assortment of hot and cold snacks. Deli2go zone was designed and assembled in such a way that its originality would encourage and attract customers while simultaneously maintaining harmony with the pre-existing units of the shop and the style adopted for the entire network.
          <br/>
          The implementation actions included preparation of new built-in units and furniture, disassembly of the previous ones, as well as adaptation of the existing electrical and water installations to the necessary specifications of the new devices. Thanks to the experience of our assembly teams, the client’s anticipated one-week average for one built-in unit was shortened to 3 days.
          <br/>
          In construction terms, in order to maintain a&nbsp;uniform line of decorations, each of the fitting modules (built-up units and furniture) was developed as a&nbsp;parametric model, leaving the scalability option in order to adjust it to a typical configurations of the POS. The elements adjusted in such a way were used for preparation of individual planograms for each station and allowed for simultaneous cutting of the material, thus optimising manufacturing and logistics costs.
          <br/>
          We designed and rebranded the interior of 233 Shell stations, ensuring ergonomic layouts wherever possible, taking into account equipment used for food and drinks, being differentiated in terms of size and function - refrigeration racks, coffee machines, convection ovens, and so on.
        </>
      ),
      href: '/en/case-study/shell-deli2go',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_3.childImageSharp.fluid}
        />
      ),
    },
    {
      title: (
        <>
          VOX – a&nbsp;modular display system
        </>
      ),
      shortText: (
        <>
          VOX shops is a network of interior
          design stores offering customers the ability to arrange room layouts in one place. Floor panels and accessories therein are one of the basic product categories in room planning and this is why...
        </>
      ),
      text: (
        <>
          VOX shops is a network of interior
          design stores offering customers the ability to arrange room layouts in one place. Floor panels and accessories therein are one of the basic product categories in room planning and this is why legible and attractive displays providing the background for these materials is important. We took these features into account for the Kerradeco display system project, combining white colour and light wood in a universally modular set of stands.
          <br/>
          This system allows the client to combine the individual elements depending on the display needs of the individual shops, in terms of both quantity and type. The individual modules, inter alia, floor panels, vinyl panels, skirting boards, and underlays, are all separated by means of an info panel guaranteeing access to customer samples.
          <br/>
          Our materials are designed in such a way as to ensure easy storage, transport, and assembly at the POS and this is why we apply the construction and technological solutions used in the furniture industry. We equipped over 150 VOX shops all over Poland with our POSM.
        </>
      ),
      href: '/en/case-study/vox',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_4.childImageSharp.fluid}
        />
      ),
    },
  ];

};
