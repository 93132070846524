import React from 'react';

import {useProjectsImage} from './useProjectsImage';
import Image from 'gatsby-image';

export const useEnProjectsData = () => {
  const imgs = useProjectsImage();
  return [
    [
      {
        title: 'FMCG',
        description: 'Impulse sales support in shops',
        url: '/en/fmcg',
        img:
          <Image fixed={imgs.cheetos.childImageSharp.fixed}/>,
      },
      {
        title: 'Pharmaceutical',
        description: 'Advertising material for pharmacies',
        url: '/en/pharmaceutical',
        img:
          <Image fixed={imgs.farm.childImageSharp.fixed}/>,
      },
      {
        title: 'Refrigeration',
        description: 'Decoration and refreshment of commercial refrigerators',
        url: '/en/refrigeration',
        img:
          <Image fixed={imgs.lech.childImageSharp.fixed}/>,
      },
      {
        title: 'Flooring',
        description: 'Displays for floor panels, carpets and accessories',
        url: '/en/flooring',
        img:
          <Image fixed={imgs.pod.childImageSharp.fixed}/>,
      },
      {
        title: 'Ceramics',
        description: 'Exposition of tiles and sanitary ceramics',
        url: '/en/ceramic',
        img:
          <Image fixed={imgs.cer.childImageSharp.fixed}/>,
      },
      {
        title: 'Other',
        description: 'Support materials for other industries',
        url: '/en/others',
        img:
          <Image fixed={imgs.b_inne.childImageSharp.fixed}/>,
      },
    ],
    [
      {
        title: 'Large POSMs',
        description: 'Racks and floor displays of larger format.',
        url: 'en/large-poms',
        img:
          <Image fixed={imgs.jordan.childImageSharp.fixed}/>,
      },
      {
        title: 'Small POSMs',
        description: 'Dispensers and stands for counter, shelf, cash register.',
        url: '/en/small-poms',
        img:
          <Image fixed={imgs.safety.childImageSharp.fixed}/>,
      },
      {
        title: 'Shop-in-shop',
        description: 'System stands, interior design, shop walls.',
        url: '/en/shop-in-shop',
        img:
          <Image fixed={imgs.shop.childImageSharp.fixed}/>,
      },
      {
        title: 'Plywood stands',
        description: 'Dedicated POS stands made of plywood.',
        url: '/en/plywood-stands',
        img:
          <Image fixed={imgs.stojaki.childImageSharp.fixed}/>,
      },
      {
        title: 'Other',
        description: 'Other materials.',
        url: '/en/type-others',
        img:
          <Image fixed={imgs.inne.childImageSharp.fixed}/>,
      },
    ],
  ];
};
